import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../..';
import { checkAuthorization } from '../../../helpers'
import { message } from 'antd'


export const getScannerFormDetails = createAsyncThunk('getscannerForm',
    async (_, { dispatch, getState }) => {

        const {userToken}= checkAuthorization()
        dispatch(gettingScannerForm())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        var requestOptions = {
            method: 'Get',
            headers: myHeaders,
            redirect: 'follow'
        }
        fetch(domainUrl + `/viewScannerFormDetail`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) 
                    dispatch(scannerFormResponse(result?.data?.length ? result?.data[0] : {}))
                else 
                    throw result?.message
            })
            .catch(error => { 
                dispatch(scannerFormClear())
                message.error(error) 
            })
    }
)

const ScannerFormSlice = createSlice({
    name: 'scannerForm',
    initialState: {
        data: null,
        loading: false
    },
    reducers: {
        gettingScannerForm: (state) => {
            state.loading = true
        },
        scannerFormResponse: (state, action) => {
            state.data = action.payload;
            state.loading = false
        },
        scannerFormClear: (state) => {
            state.data = null;
            state.loading = false
        }
    },
})
export const { 
    gettingScannerForm, scannerFormResponse, scannerFormClear
} = ScannerFormSlice.actions;
export default ScannerFormSlice.reducer;