import React,{ useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {Row, Col, Form, Typography, Table, Button, Space, Image} from 'antd'
import { ActionButton, AddUpdateCampaign, DeleteModal, ModuleTopHeading, StoreInput, StoreSelect, StoreTimePicker, WinnersDrawer} from '../../components';
import { actionsApi, apiCalls, localToUtcOnlyDate2, TableLoader } from '../../shared'
import { altImage } from '../../data';
import { DeleteOutlined } from '@ant-design/icons';

const {Text}= Typography
let timer
const CampaignOverView = () => {

    const [form] = Form.useForm()
    const dispatch= useDispatch()
    const {data, loading, campaignsLookup, filters, pagination}= useSelector(state => state?.campaignOverview)
    const [visible, setVisible]= useState(false)
    const [rowData, setRowData]= useState(null)
    const [visibleWinnersDrawer, setVisibleWinnersDrawer]= useState(false)
    const [campaignID, setCampaignID]= useState(null)
    const [campaignDetail, setCampaignDetail]= useState(null)
    const [visibleRemoveModal, setVisibleRemoveModal]= useState(false)
    const [removeScannerLoading, setRemoveScannerLoading]= useState(false)

    useEffect(()=>{
        const {range}= filters
        let fromDate=null
        let toDate= null
        if(range?.length)
        {
            fromDate= localToUtcOnlyDate2(new Date(range[0]))
            toDate= localToUtcOnlyDate2(new Date(range[1]))
        }
        dispatch(actionsApi?.getCampaignsLookup({fromDate, toDate}))
    }, [])

    const getCampaignsLookup =()=>{
            setCampaignID(null)
            const {range}= form.getFieldsValue()
            let fromDate=null
            let toDate= null
            if(range?.length)
            {
                fromDate= localToUtcOnlyDate2(new Date(range[0]))
                toDate= localToUtcOnlyDate2(new Date(range[1]))
            }
            dispatch(actionsApi?.getCampaignsLookup({fromDate, toDate}))
            dispatch(actionsApi?.changeCampaignOverviewFilters({range, campaignID: null}))
    }
    useEffect(()=>{
        if(campaignsLookup?.length)
        {
            let campaignID= filters?.campaignID || campaignsLookup[0]?.id
            let name= filters?.name || null
            setCampaignID(campaignID)
            setCampaignDetail(campaignsLookup?.find(fin => fin?.id===campaignID))
            dispatch(actionsApi?.viewCampaignOverviewByID({campaignID, name, ...pagination, pageNo: 1}))
            dispatch(actionsApi?.changeCampaignOverviewFilters({campaignID, name}))
            form.setFieldsValue({
                ...form.getFieldsValue(),
                campaignID,
                name
            })
        }
        else{
            let campaignID= null
            let name=  null
            setCampaignID(campaignID)
            setCampaignDetail(null)
            dispatch(actionsApi?.campaignOverviewClear())
            dispatch(actionsApi?.changeCampaignOverviewFilters({campaignID, name}))
            form.setFieldsValue({
                ...form.getFieldsValue(),
                campaignID,
                name
            })
        }
    }, [campaignsLookup])


    const filter=(campaignID)=>{
        const {name}= form.getFieldsValue()
        dispatch(actionsApi?.viewCampaignOverviewByID({campaignID, name, ...pagination, pageNo:1}))
        dispatch(actionsApi?.changeCampaignOverviewFilters({campaignID, name}))
    }
    const call=(pageNo=1, pageSize=20)=>{
        dispatch(actionsApi?.viewCampaignOverviewByID({...filters, pageNo, pageSize}))
    }

    function searchHandler(name, pageNo=1, pageSize=20) {
        dispatch(actionsApi?.viewCampaignOverviewByID({...filters, name, pageNo, pageSize}))
        dispatch(actionsApi?.changeCampaignOverviewFilters({campaignID: filters?.campaignID, name}))
    }
    function debounce(func, delay) {
        return function(...args) {
            clearTimeout(timer)
            timer = setTimeout(() => {
                func.apply(this, args)
            }, delay)
        };
    }
    const debouncedSearchHandler = debounce(searchHandler, 400)
    const columns = [
        {
            title: 'Slip',
            dataIndex: 'slip',
            key: 'slip',
            render: column =>  
                <Image 
                    width={90} 
                    src={column} 
                    alt='damodar scanner slip'
                    fallback={altImage}
                />
        },
        {
            title: <Text>Scanner Name</Text>,
            dataIndex: 'name',
            key: 'name',
            render: column => <Text strong>{column}</Text>
        },
        {
            title: <Text>Email</Text>,
            dataIndex: 'email',
            key: 'email',
            render: column => <Text>{column}</Text>
        },
        {
            title: <Text>phone #</Text>,
            dataIndex: 'phone',
            key: 'phone',
            render: column => <Text>{column}</Text>
        },
        {
            title: <Text>Transaction ID</Text>,
            dataIndex: 'transactionNumber',
            key: 'transactionNumber',
            render: column => <Text>{column}</Text>
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (_, row) => {
                return (
                    <ActionButton
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => {setVisibleRemoveModal(true); setRowData(row?.id)}}
                    />
                )
            }
        }
    ]
    const removeScanner= async ()=>{
        setRemoveScannerLoading(true)
        let result=await apiCalls?.campaignOverview?.removeScanner(rowData)
        setRowData(null)
        setRemoveScannerLoading(false)
        setVisibleRemoveModal(false)
        if(result)
            call()
    }
  return (
    <>
        <Row 
            gutter={[12,12]} 
            align={'middle'}
        >
            <Col md={24}>
                <ModuleTopHeading
                    name='Campaign Overview'
                />
            </Col>
            <Col md={24}>
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={true}
                    onFinish={filter}
                >
                    <Space size={15}>
                            <StoreTimePicker
                                rangePicker
                                name='range'
                                label='From date - To date'
                                format='MM/DD/YYYY'
                                onChange={getCampaignsLookup}
                            />
                            <StoreSelect
                                name="campaignID"
                                label={'Campaign'}
                                value={form.getFieldValue('campaignID') || ''}
                                placeholder='select...'
                                options={campaignsLookup?.map(campaign => ({id: campaign?.id, name: campaign?.name}))}
                                onChange={(value)=>{
                                    filter(value)
                                    setCampaignID(value)
                                    setCampaignDetail(campaignsLookup?.find(fin => fin?.id===value))
                                }}
                                style={{width:'250px'}}
                            />
                            <StoreInput
                                name="name"
                                label={'Name'}
                                value={form.getFieldValue('name') || ''}
                                placeholder='John'
                                onChange={(e)=> debouncedSearchHandler(e.target.value,1, 20)}
                                style={{width:'250px'}}
                            />
                            <Button 
                                type='primary'
                                onClick={() => setVisibleWinnersDrawer(true)}
                            >
                                Choose Winner
                            </Button>
                    </Space>
                </Form>
            </Col>
            <Col md={24}>
                <Table 
                    size='large'
                    columns={columns} 
                    dataSource={data} 
                    pagination={{ 
                        hideOnSinglePage: true, 
                        total: pagination?.totalRecords,
                        pageSize: pagination?.pageSize,
                        defaultPageSize: pagination?.pageSize,
                        current: pagination?.pageNo,
                        size: "default",
                        pageSizeOptions:['20', '50', '100'],
                        onChange: (pageNo, pageSize)=> call(pageNo, pageSize),
                        showTotal: total => <Button>Total Apps: {total}</Button>
                    }}
                    loading={
                        {
                            ...TableLoader,
                            spinning: loading
                        }
                    }
                />
            </Col>
        </Row>
        <AddUpdateCampaign
            visible={visible}
            getCampaigns={call}
            catData={rowData}
            onClose={()=> {setVisible(false); setRowData(null)}}
        />
        <WinnersDrawer
            visible={visibleWinnersDrawer}
            campaignID={campaignID}
            campaignDetail= {campaignDetail}
            onClose={()=>{setVisibleWinnersDrawer(false)}}
        />
        <DeleteModal
            visible={visibleRemoveModal}
            title= 'entry'
            onClose={()=>{ setVisibleRemoveModal(false); setRowData(null)}}
            removeItem={removeScanner}
            loading={removeScannerLoading}
        />
    </>
  )
}

export {CampaignOverView}