import { checkAuthorization, domainUrl } from "../.."
import { message } from "antd"
const addScannerFormDetails = (formData) => {
    const {userToken}= checkAuthorization()
    var myHeaders = new Headers();
    myHeaders.append("Authorization", userToken)
    var requestOptions = {
      method: 'POST',
      redirect: 'follow',
      headers: myHeaders,
      body: formData
    }
    let url= formData.get("id") ? '/updateScannerFormDetail' : '/storeScannerFormDetail'
    return (
        fetch(domainUrl + url, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success(result?.message)
                return 1
            }
            else 
                throw result?.message
        })
        .catch(error => {
            message.error(error)
            return 0
        })
    )
}
const scannerFormDetails={
    addScannerFormDetails,
}
export {scannerFormDetails}