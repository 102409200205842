import React, { useState, useRef, useEffect} from "react"
import { Drawer, Form, Row, Col ,Button, Space} from "antd"
import { StoreTimePicker, StoreInput, StoreSelect } from "../../Forms"
import { apiCalls, localToUtcOnlyDate, utcToLocal, utcToLocalOnlyDate } from "../../../shared"
import dayjs from "dayjs"

const AddUpdateCampaign= ({visible, editData, getCampaigns, onClose})=>{

    const [form] = Form.useForm()
    const btnRef = useRef() 
    const selectButton = () => btnRef.current.click()
    const [loading, setLoading]= useState(false)
    useEffect(()=>{
        if(visible && editData)
        {
            console.log(utcToLocal(editData?.startDate),":", utcToLocal(editData?.endDate))
            form.setFieldsValue({
                ...editData,
                startDate: dayjs(utcToLocal(editData?.startDate)),
                endDate: dayjs(utcToLocal(editData?.endDate))
            })
        }
        else if(visible)
            form.setFieldsValue({campaignType:1})
        else 
            form.resetFields()
    }, [editData])
    const addUpdateCategory= async ()=>{
        setLoading(true)
        let data=form.getFieldsValue()
        if(editData?.id)
            data={
                id: editData?.id,
                startDate: convertDate(data?.startDate),
                endDate: convertDate(data?.endDate),
                winnerLimit: parseInt(data?.winnerLimit)
            }
        else
            data={
                ...data,
                startDate: convertDate(data?.startDate),
                endDate: convertDate(data?.endDate),
            }
        const result= await apiCalls?.campaign?.addUpdateCampaign(data)
        setLoading(false)
        onClose()
        if(result)
            getCampaigns()
    }
    const convertDate= (date)=>{
        return localToUtcOnlyDate(new Date(dayjs(date).format("YYYY-MM-DD HH:mm:ss")))
    }
    return (
        <Drawer
            title='Add new campaign'
            onClose={onClose}
            open={visible}
            width={600}
            footer={
                <div>
                    <Space>
                        <Button
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <Button  
                            type="primary"
                            loading={loading}
                            onClick={selectButton}
                        >
                            Save Camapaign
                        </Button>
                    </Space>
                </div>
            }
        >
            <div className="flex-col-Space-between height-100">
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={true}
                    onFinish={()=> addUpdateCategory()}
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <StoreInput
                                name='name'
                                label='Campaign name'
                                required
                                message='Please enter campaign name'
                                placeholder='e.g Happy diwali'
                                value={form.getFieldValue('name')}
                                disabled={editData}
                            />
                        </Col>
                        <Col span={12}>
                            <StoreInput
                                type='number'
                                name='limit'
                                label='Total QRs'
                                required
                                message='Please enter limit'
                                placeholder='e.g 150'
                                value={form.getFieldValue('limit')}
                                disabled={editData}
                            />
                        </Col>
                        <Col span={12}>
                            <StoreInput
                                type='number'
                                name='winnerLimit'
                                label='Limit of winners for this campaign'
                                required
                                message='Please enter limit of  winners  for this campaign'
                                placeholder='e.g 4'
                                value={form.getFieldValue('winnerLimit')}
                                validator={() => ({
                                    validator(_, value) {
                                        if(!editData)
                                            return Promise.resolve()
                                        if (value>= editData?.winnerLimit) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('winners limit Should not be less than previous limit'));
                                    },
                                })}
                            />
                        </Col>
                        <Col span={12}>
                            <StoreSelect
                                name='campaignType'
                                label='Campaign Type'
                                required
                                message='Please campaign type'
                                value={form.getFieldValue('campaignType')}
                                disabled={editData}
                                options={[
                                    {id:1, name: 'Single'},
                                    {id:2, name: 'Unique'},
                                ]}
                            />
                        </Col>
                        <Col span={12}>
                            <StoreTimePicker
                                datePicker
                                name='startDate'
                                label='Campaign start date'
                                required
                                message='Please select campaign start date'
                                placeholder='e.g MM-DD-YYYY'
                            />
                        </Col>
                        <Col span={12}>
                            <StoreTimePicker
                                datePicker
                                name='endDate'
                                label='Campaign end date'
                                required
                                message='Please select campaign end date'
                                placeholder='e.g MM-DD-YYYY'
                            />
                        </Col>
                        
                    </Row>
                    <Button 
                        htmlType="submit" 
                        ref={btnRef}
                        className="display-none"
                    >
                        Save Campaign
                    </Button>
                </Form>
            </div>
        </Drawer>
    )
}
export {AddUpdateCampaign}