import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../..';
import { checkAuthorization } from '../../../helpers'
import { message } from 'antd'


export const viewAllCampaigns = createAsyncThunk('getCampaigns',
    async (object, { dispatch, getState }) => {

        const {userToken}= checkAuthorization()
        dispatch(gettingCampaigns())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append("pageNO", object?.pageNo)
        myHeaders.append("recordsPerPage", object?.pageSize)
        var requestOptions = {
            method: 'Post',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({name: object?.name, fromDate: object?.fromDate, toDate: object?.toDate})
        };
        fetch(domainUrl + `/viewAllCampaign`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    dispatch(campaignsResponse(result?.campaigns))
                    dispatch(setPages({pageNo: object?.pageNo, pageSize: object?.pageSize, totalRecords: result?.totalRecords}))
                }
                else 
                    throw result?.message
            })
            .catch(error => { 
                dispatch(campaignsClear())
                message.error(error) 
            })
    }
)
export const getQrsByCampaignID = createAsyncThunk('getQrs',
    async (object, { dispatch, getState }) => {

        const {userToken}= checkAuthorization()
        dispatch(gettingQrs())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append("pageNO", object?.pageNo)
        myHeaders.append("recordsPerPage", object?.pageSize)
        var requestOptions = {
            method: 'get',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(domainUrl + `/getQRsByCampaginID/${object?.campaignID}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    dispatch(qrsResponse(result?.QRs))
                    dispatch(setQrsPages({pageNo: object?.pageNo, pageSize: object?.pageSize, totalRecords: result?.totalRecords}))
                }
                else 
                    throw result?.message
            })
            .catch(error => { 
                dispatch(qrsClear())
                message.error(error) 
            })
    }
)

const CampaignsSlice = createSlice({
    name: 'campaigns',
    initialState: {
        data: null,
        loading: false,
        pagination:{
            pageNo:1,
            pageSize: 5,
            totalRecords:0,
        },
        filters: {
            name: '',
            range:[]
        },
        qrs:[],
        qrsLoading:false,
        qrsPagination:{
            pageNo:1,
            pageSize: 12,
            totalRecords:0,
        },
    },
    reducers: {
        gettingCampaigns: (state) => {
            state.loading = true
        },
        campaignsResponse: (state, action) => {
            state.data = action.payload;
            state.loading = false
        },
        campaignsClear: (state) => {
            state.data = null;
            state.loading = false
        },
        changeCampaignsFilters: (state, action) => {
            state.filters = action.payload
        },
        setPages:(state, action)=>{
            state.pagination= action.payload
        },
        gettingQrs: (state) => {
            state.qrsLoading = true
        },
        qrsResponse: (state, action) => {
            state.qrs = action.payload
            state.qrsLoading = false
        },
        qrsClear: (state) => {
            state.qrs = []
            state.qrsLoading = false
            state.qrsPagination={
                pageNo:1,
                pageSize: 12,
                totalRecords:0
            }
        },
        setQrsPages:(state, action)=>{
            state.qrsPagination= action.payload
        },
    },
})
export const { 
    gettingCampaigns, campaignsResponse, campaignsClear, changeCampaignsFilters, setPages,
    gettingQrs, qrsResponse, qrsClear, setQrsPages
} = CampaignsSlice.actions;
export default CampaignsSlice.reducer;