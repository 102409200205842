import { Modal, Space, Typography } from "antd"
import { apiCalls } from "../../../shared"
import { useState} from "react"
import { StoreInputPin } from "../../Forms"
import { LoadingOutlined } from "@ant-design/icons"
const { Text }= Typography

const SendMailModal= ({visible, campaignID, winnerID, callBack, winner, onClose})=>{

    const [loading, setLoading]= useState(false)
    const sendMail= async (pin)=>{
        setLoading(true)
        let result= await apiCalls?.campaignOverview?.sendMail({campaignID, winnerID, pin})   
        if(result){
            callBack()
            onClose()
        }
        setLoading(false)
    }

    return (
        <Modal 
            title={<Text className="brand-color">Send Mail</Text>}
            open={visible} 
            onOk={onClose} 
            onCancel={onClose}
            footer={null}
            centered
            destroyOnClose
            closeIcon={loading ? <LoadingOutlined/> : ''}
            width={450}
        >
            <Space 
                direction="vertical"
                className="flex-col-center width-100"
            >
                <Space direction="vertical" className="width-100">
                    <Text><Text strong>Name:</Text> {winner?.name || '-'}</Text>
                    <Text><Text strong>Email:</Text> {winner?.email || '-'}</Text>
                    <Text><Text strong>Phone:</Text> {winner?.phone || '-'}</Text>
                    <Text><Text strong>Transaction ID:</Text> {winner?.transactionNumber || '-'}</Text>
                </Space>
                <Text strong>
                    Enter PIN below to send mail to winner
                </Text>
                <StoreInputPin
                    visible={visible}
                    onComplete={sendMail}
                />    
            </Space>
        </Modal>
    )
}
export {SendMailModal}