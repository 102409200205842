import dayjs from 'dayjs'
import moment from "moment"

const simpleDateFormat= (date)=>{
    return date ? dayjs(date).format('DD MMMM YYYY') : null
}
const simpleDateFormatUtcToLocal= (date)=>{
    return date ? moment.utc(date).local().format('DD MMMM YYYY HH:mm:ss') : null
}


export const localToUtc=(dateTime)=>{
    if(!dateTime)
    return
    return (moment.utc(dateTime).format('YYYY-MM-DD HH:mm:ss'))
}
export const localToUtcOnlyDate=(dateTime)=>{
    if(!dateTime)
    return
    return (moment.utc(dateTime).format('YYYY-MM-DD'))
}
export const localToUtcOnlyDate2=(dateTime)=>{
    if(!dateTime)
    return
    return (moment.utc(dateTime).format('YYYY-MM-DD'))
}

export const utcToLocal=(dateTime)=>{
    if(!dateTime)
    return
    return (moment.utc(dateTime).local().format('YYYY-MM-DD HH:mm:ss'))
}
export const utcToLocalOnlyDate=(dateTime)=>{
    if(!dateTime)
    return
    return (moment.utc(dateTime).local().format('YYYY-DD-MM'))
}



export { 
    simpleDateFormat,
    simpleDateFormatUtcToLocal
}