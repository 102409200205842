import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants'
import { checkAuthorization } from '../../../helpers';
import { message } from 'antd'

export const getStatistics = createAsyncThunk('getStatistics',
    async (_, { dispatch, getState }) => {

        const {userToken}= checkAuthorization()
        dispatch(gettingStatistics())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        var requestOptions = {
            method: 'get',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(domainUrl + `/statistics`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    dispatch(statisticsResponse(result))
                }
                else 
                    throw result?.message
            })
            .catch(error => { 
                dispatch(statisticsClear())
                message.error(error) 
            })
    }
)
export const viewCampaignsStatistics = createAsyncThunk('campaignsStatistics',
    async (object, { dispatch, getState }) => {

        const {userToken}= checkAuthorization()
        dispatch(gettingCampaignsStatistics())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append("pageNO", object?.pageNo)
        myHeaders.append("recordsPerPage", object?.pageSize)
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({name: object?.name, order: object?.order})
        };
        fetch(domainUrl + `/campaignStatistics`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    dispatch(campaignsStatisticsResponse(result?.campaigns))
                    dispatch(setPages({pageNo: object?.pageNo, pageSize: object?.pageSize, totalRecords: result?.totalRecords}))
                }
                else 
                    throw result?.message
            })
            .catch(error => { 
                dispatch(campaignsStatisticsClear())
                message.error(error) 
            })
    }
)

const DashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        data: null,
        loading: false,
        campaignsStatistics: null,
        loadingCampaignsStatistics: false,
        pagination:{
            pageNo:1,
            pageSize: 10,
            totalRecords:0,
        },
        filters: {
            name: '',
            order: 1,
        },
    },
    reducers: {
        gettingStatistics: state => {
            state.loading=true
            state.data=null
        },
        statisticsResponse: (state, action)=>{
            state.data= action.payload
            state.loading= false
        },
        statisticsClear: (state)=>{
            state.data= null
            state.loading= false
        },
        gettingCampaignsStatistics: state => {
            state.loadingCampaignsStatistics=true
        },
        campaignsStatisticsResponse: (state, action)=>{
            state.campaignsStatistics= action.payload
            state.loadingCampaignsStatistics= false
        },
        campaignsStatisticsClear: (state)=>{
            state.campaignsStatistics= null
            state.loadingCampaignsStatistics= false
        },
        changeCampaignsStatisticsFilters: (state, action) => {
            state.filters = action.payload
        },
        setPages:(state, action)=>{
            state.pagination= action.payload
        },
    },
})
export const { 
    gettingStatistics, statisticsResponse, statisticsClear,
    gettingCampaignsStatistics, campaignsStatisticsResponse, campaignsStatisticsClear,
    changeCampaignsStatisticsFilters, setPages
} = DashboardSlice.actions;
export default DashboardSlice.reducer;