import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Card, Col, Row, Typography,Space, theme, Form, Dropdown, Table, Button} from 'antd'
import { StoreInput } from '../../components'
import { TableLoader, actionsApi, simpleDateFormat } from '../../shared';
import { FilterOutlined, SearchOutlined } from '@ant-design/icons';
const { useToken } = theme;
const { Title, Text } = Typography
let timer

const ApplicationsStatistics = () => {
    const dispatch= useDispatch()
    const [form] = Form.useForm()
    const { token } = useToken()
    const {campaignsStatistics, loadingCampaignsStatistics, filters, pagination}= useSelector(state => state?.dashboard)
    const [order, setOrder]= useState(1)
    const items = [
        {
            label: <Text>A-Z</Text>,
            key: '0',
            disabled: order ===1,
        },
        {
            label: <Text>Z-A</Text>,
            key: '1',
            disabled: order === 2,
        },
        {
            label: <Text>Most Scanned Qr</Text>,
            key: '2',
            disabled: order === 3,
        },
        {
            label: <Text>Less Scanned Qr</Text>,
            key: '3',
            disabled: order === 4,
        },
    ];


    const columns = [
    {
        title: <Text>Name</Text>,
        dataIndex: 'name',
        key: 'name',
        render: column => <Text strong>{column}</Text>
    },
    {
        title: <Text>Start date</Text>,
        dataIndex: 'startDate',
        key: 'startDate',
        render: column => <Text>{simpleDateFormat(column)}</Text>
    },
    {
        title: <Text>End Date</Text>,
        dataIndex: 'endDate',
        key: 'endDate',
        render: column => <Text>{simpleDateFormat(column)}</Text>
    },
    {
        title: <Text>Total QR</Text>,
        dataIndex: 'QRs',
        key: 'QRs',
        render: column => <Text>{column}</Text>
    },
    {
        title: <Text>Scanned QR</Text>,
        dataIndex: 'campaignScannedQRs',
        key: 'campaignScannedQRs',
        render: column => <Text>{column}</Text>
    },
    {
        title: <Text>Winners limit</Text>,
        dataIndex: 'winnerLimit',
        key: 'winnerLimit',
        render: column => <Text>{column}</Text>
    },
    {
        title: <Text>Winners</Text>,
        dataIndex: 'winners',
        key: 'winners',
        render: column => {
            return (
                <ul direction='vertical'>
                    {
                        column?.map((winner, index) => (<li strong key={'winner-'+index}>{winner?.name}</li>))
                    }
                </ul>
            )
        }
    },
    ]

    useEffect(()=>{
       dispatch(actionsApi?.viewCampaignsStatistics({...filters, ...pagination, pageNo: 1}))
        form.setFieldsValue({
            ...filters,
        })
        setOrder(filters?.order)
    }, [])
    const filter=(order)=>{
        let data= form.getFieldsValue()
        dispatch(actionsApi?.viewCampaignsStatistics({...data, order, ...pagination, pageNo:1}))
        dispatch(actionsApi?.changeCampaignOverviewFilters({...data, order}))
    }
    const call=(pageNo=1, pageSize=5)=>{
        const {name}= form.getFieldsValue()
        dispatch(actionsApi?.viewCampaignsStatistics({...filters, name, pageNo, pageSize}))
        dispatch(actionsApi?.changeCampaignOverviewFilters({...filters, name}))
    }
    const onClick = ({ key }) => {
        key = parseInt(key) + 1
        setOrder(key)
        filter(key)
      };
      function debounce(func, delay) {
        return function(...args) {
            clearTimeout(timer)
            timer = setTimeout(() => {
                func.apply(this, args)
            }, delay)
        };
    }
    const debouncedSearchHandler = debounce(()=> call(1, 5), 600)

  return (
    <div>
        <Card 
            style={{borderRadius: token?.cardRadius}}
            className='border-grey'
            >
            <Row gutter={[24,24]}>
                <Col md={12}>
                    <div>
                        <Title level={3} className='my-0'>Campaigns</Title>
                    </div>
                </Col>
                <Col md={12}>
                    <Space 
                        align='baseline' 
                        className='w-100 search-bar-cover' 
                        size={10} 
                        style={{justifyContent:"end"}}
                        >
                        <Form
                            form={form}
                            layout="vertical"
                            initialValues={true}
                            onFinish={filter}
                        >
                            <StoreInput
                                name="name"
                                size='large'
                                label={null}
                                value={form.getFieldValue('name') || ''}
                                suffix={<SearchOutlined/>}
                                placeholder='search campaign'
                                className='page-top-search-bar'
                                onChange={debouncedSearchHandler}
                            />
                        </Form>
                        <Dropdown
                            menu={{
                            items,
                            onClick
                            }}
                            trigger={['click']}
                            arrow
                            icon={<FilterOutlined />}
                        >
                            <Button
                                type='primary'
                                icon={<FilterOutlined/>}
                                size='large'
                            >
                                Filter
                            </Button>
                        </Dropdown>
                    </Space>
                </Col>
                <Col span={24}>
                    <Table 
                        columns={columns} 
                        dataSource={campaignsStatistics} 
                        pagination={{ 
                            hideOnSinglePage: true, 
                            total: pagination?.totalRecords,
                            pageSize: pagination?.pageSize,
                            defaultPageSize: pagination?.pageSize,
                            current: pagination?.pageNo,
                            size: "default",
                            pageSizeOptions:['20', '50', '100'],
                            onChange: (pageNo, pageSize)=> call(pageNo, pageSize),
                            showTotal: total => <Button>Total: {total}</Button>
                        }}
                        loading={
                            {
                              ...TableLoader,
                              spinning: loadingCampaignsStatistics
                            }
                        }
                    />
                </Col>
            </Row>
        </Card>
    </div>
  )
}

export {ApplicationsStatistics}