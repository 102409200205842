import { categories } from "./Categories"
import { applications } from "./Applications"
import { campaign } from "./Campaign"
import { scannerFormDetails } from "./ScannerFormDetails"
import { campaignOverview } from "./Campaign Overview"
const apiCalls={
    categories,
    applications,
    campaign,
    campaignOverview,
    scannerFormDetails
}
export {apiCalls}