import React, { useState, useEffect} from 'react';
import { Upload, Form, Typography} from 'antd';
import "./index.css"

const StoreSingleUpload = ({label, name, required, message, form, logo}) => {
  const [fileList, setFileList] = useState([])
  const onChange = ({ fileList: newFileList }) => {
    let file= newFileList?.length ? [newFileList[newFileList?.length-1]] : []
    setFileList(file)
    form.setFieldsValue({...form.getFieldsValue, [name]: file?.length ? file[0]?.originFileObj : null})
  }
  const onPreview = async (file) => {
    let src = !file?.name ? logo : file.url
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      })
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  }
  useEffect(()=>{
    if(logo)
    {
      setFileList([{thumbUrl: logo}])
    }
    else
    {
      setFileList([])
    }
  }, [logo])

  return (
    <Form.Item
      name={name}
      label={<Typography.Text strong>{label}</Typography.Text>}
      rules={[
          {
          required,
          message,
          },
      ]}
      >
        <Upload
          name={name}
          action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
          listType="picture-card"
          fileList={fileList}
          onChange={onChange}
          onPreview={onPreview}
          accept=".jpg, .jpeg, .png"
        >
          {fileList.length < 5 && '+ Upload'}
        </Upload>
    </Form.Item>
  );
};
export {StoreSingleUpload}