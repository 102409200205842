import { useState } from 'react';
import { Image, Modal, Space, Typography, Button } from 'antd'
import { apiCalls } from '../../../shared'
import { StoreInputPin } from '../../Forms';
const { Title, Text } = Typography

const RemoveModal = ({visible, campaignID, getCampaigns, onClose}) => {
   
    const [loading, setLoading]= useState(false)
    
    const removeCampaign= async (pin)=>{
        setLoading(true)
        let result= await apiCalls?.campaign?.removeCampaign({pin: parseInt(pin), id: campaignID})
        if(result)
        {
            getCampaigns()
            onClose()
        }
        setLoading(false)
    }
  return (
    <div>
        <Modal 
            width={500}  
            open={visible} 
            onOk={onClose} 
            onCancel={onClose} 
            centered 
            footer={
                <Button 
                    block
                    size="large"
                    onClick={onClose}
                    style={{width: '120px'}}
                >
                    Cancel
                </Button>
            }
        >
            <Space className='w-100 py-2' align='center' size={10} direction='vertical'>
                <Image 
                    src='/assets/icons/warning.png' 
                    alt='warning remove item'
                    width={55}
                    preview={false} 
                />
                <Title level={4} className='my-0'>Alert</Title>
                <Text>Are you sure you want to permanently delete the campaign?</Text>
                <StoreInputPin
                    visible={visible}
                    onComplete={removeCampaign}
                />
            </Space>
        </Modal>
    </div>
  )
}

export {RemoveModal}