import React from 'react';
import { ConfigProvider, theme } from 'antd';
import RouteF from './RouteF';
import { BrowserRouter } from 'react-router-dom';
import {store} from "./shared";
import { Provider } from "react-redux";

function App() {
  return (
    <div>
      <BrowserRouter>
        <ConfigProvider
          theme={{
            components:{
              DatePicker:{
                colorPrimary:'#EB3412',
                colorLink:'#EB3412'
              }
            },
            token: {
              colorPrimary: '#EB3412',
              borderRadius: 6,
              borderrad2: 8,
              borderrad3: 5,
              cardRadius: '20px',
              borderCardcolor: '#fff',
              cardshadow:'0px 4px 25px 0px #D9D9D9',
              mainColor: '#EB3412',
              secondarColor:'#04103B',
              colorgray: '#949494',
              bgtransparent:'transparent',
              border2:'#D9D9D9'
            },
          }}
        >
          <Provider store={store}>
            <RouteF theme={theme.components} />
          </Provider>
        </ConfigProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
