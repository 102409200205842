import { Modal, Space, Typography, Image, Form} from "antd"
import { apiCalls, localToUtcOnlyDate, localToUtcOnlyDate2, utcToLocal } from "../../../shared"
import { useState, useEffect} from "react"
import "./index.css"
import { StoreInputPin, StoreTimePicker } from "../../Forms"
import { LoadingOutlined } from "@ant-design/icons"
import Confetti from 'react-confetti'
import { altImage } from "../../../data"
import dayjs from "dayjs"

const {Title, Text}= Typography

const ChooseWinnerModal= ({visible, campaignID, campaignDetail, winnerID, callBack, onClose})=>{

    const [form] = Form.useForm()
    const [loading, setLoading]= useState(false)
    const [timeLeft, setTimeLeft] = useState(10)
    const [winner, setWinner]= useState(null)
    const [dimesion, setDimension]= useState(null)
    let countdownTimer


    useEffect(() => {
        return () => {
            clearInterval(countdownTimer)
            setTimeLeft(10)
            setWinner(null)
        }
    }, [])
    useEffect(()=>{
        if(visible)
        {
            let modal= document.getElementsByClassName("choose-winner-modal")[0]
            let width=modal.offsetWidth+'px'
            let height=modal.offsetHeight+'px'
            setDimension({width, height})
            form.setFieldsValue({
                range: [dayjs(utcToLocal(campaignDetail?.startDate)), dayjs(utcToLocal(campaignDetail?.endDate))]
            })
        }
        else
            form.resetFields()
    }, [visible])
    console.log("details:", campaignDetail?.startDate,":", dayjs(utcToLocal(campaignDetail?.startDate),":", dayjs(utcToLocal(campaignDetail?.endDate))))

    const chooseWinner= async (pin)=>{
        const {range}= form.getFieldsValue()
        let fromDate=null
        let toDate= null
        if(range?.length)
        {
            fromDate= localToUtcOnlyDate(new Date(range[0]))
            toDate= localToUtcOnlyDate(new Date(range[1]))
        }
        setLoading(true)
        let result= await apiCalls?.campaignOverview?.checkPin({pin})   
        if(result){
            result= await apiCalls?.campaignOverview?.chooseWinner({campaignID, winnerID, pin, fromDate, toDate})   
            if(result){
                const timerElement = document.querySelector('.timer')
                if (timerElement) 
                    runTimer(timerElement, result)
            }
            setLoading(false)
        }
        else {
            setLoading(false)
        }
    }
    const runTimer = (timerElement, result) => {
        const timerCircle = timerElement.querySelector('svg > circle + circle')
        timerElement.classList.add('animatable')
        timerCircle.style.strokeDashoffset = 1
        countdownTimer = setInterval( () => {
        setTimeLeft((prevTime) => {
            if (prevTime > 0) {
                const normalizedTime = (10 - prevTime + 1) / 10;
                timerCircle.style.strokeDashoffset = normalizedTime;
                return prevTime - 1;
            } 
            else {

                clearInterval(countdownTimer)
                timerElement.classList.remove('animatable')
                setWinner(result)
                callBack()
                return 0
            }
        });
        }, 1000)
    }
   
    return (
        <Modal 
            title={<Text className="brand-color">{winnerID? 'Re-draw' : "Choose Winner"}</Text>}
            open={visible} 
            onOk={onClose} 
            onCancel={onClose}
            footer={null}
            centered
            destroyOnClose
            closeIcon={loading ? <LoadingOutlined/> : ''}
            width={600}
            className="choose-winner-modal"
        >
            <Space 
                direction="vertical"
                className="flex-col-center width-100"
            >
                <Title level={5} className="brand-color my-0">
                    Are sure you want to {winnerID ? 're-draw' : 'choose'} winner of this campaign? 
                </Title>
                <div class="timer animatable">
                    <svg>
                        <circle cx="50%" cy="50%" r="90"/>
                        <circle cx="50%" cy="50%" r="90" pathLength="1" />
                        <text x="100" y="100" text-anchor="middle">
                            <tspan id="timeLeft">
                                {timeLeft}
                            </tspan>
                        </text>
                        <text x="100" y="120" text-anchor="middle">seconds</text>
                    </svg>
                </div>
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={true}
                    onFinish={()=>{}}
                >
                    <StoreTimePicker
                        rangePicker
                        name='range'
                        label='From date - To date'
                        required
                        format='MM/DD/YYYY'
                        message='Please select from-to date'
                    />
                </Form>
                <Text strong>
                    Enter PIN below to continue
                </Text>
                <StoreInputPin
                    visible={visible}
                    onComplete={chooseWinner}
                />
                
            </Space>
            <Space direction="vertical">
                <Text><Text strong>Name:</Text> <Text strong style={{color:'red'}}>{winner?.name || '-'}</Text></Text>
                <Text><Text strong>Email:</Text> {winner?.email || '-'}</Text>
                <Text><Text strong>Phone:</Text> {winner?.phone || '-'}</Text>
                <Text><Text strong>Transaction ID:</Text> {winner?.transactionNumber || '-'}</Text>
                <Space>
                    <Text strong>Slip:</Text>
                    <Image 
                        width={90} 
                        src={winner?.slip} 
                        alt='damodar scanner slip'
                        fallback={altImage}
                    />
                </Space>
            </Space>
            {
                winner ?
                <Confetti
                    width={dimesion?.width || 0}
                    height={dimesion?.height || 0}
                />
                :<></>
            }
        </Modal>
    )
}
export {ChooseWinnerModal}