
//login
import { login, forgotPassword, loginResponse, loginClear, AuthCheck } from "./action/Login"
//dashboard
import { getStatistics, viewCampaignsStatistics, changeCampaignsStatisticsFilters } from "./action/dashboard"
//campaigns
import { viewAllCampaigns, changeCampaignsFilters, getQrsByCampaignID, qrsResponse, qrsClear } from "./action/Campaigns"
// campaigns Overview
import { viewCampaignOverviewByID, campaignOverviewClear, getCampaignsLookup, changeCampaignOverviewFilters, getCampaignWinner, campaignWinnerResponse, campaignWinnerClear} from "./action/Campaigns Overview"
//Applications
import { getAllApplications, changeApplicationsFilters, getCategoriesLookup } from "./action/Applications"
//ScannerForm
import {getScannerFormDetails} from "./action/ScannerForm"

export const actionsApi = {
    login, 
    forgotPassword,
    loginResponse, 
    loginClear, 
    AuthCheck, 
    //dashobard 
    getStatistics,
    viewCampaignsStatistics, 
    changeCampaignsStatisticsFilters,
    //campaigns
    viewAllCampaigns, 
    changeCampaignsFilters,
    getQrsByCampaignID, 
    qrsResponse,
    qrsClear,
    // campaigns Overview
    viewCampaignOverviewByID, 
    campaignOverviewClear,
    getCampaignsLookup,
    changeCampaignOverviewFilters,
    getCampaignWinner,
    campaignWinnerResponse,
    campaignWinnerClear,
    //Applications
    getAllApplications,
    changeApplicationsFilters,
    getCategoriesLookup,
    //ScannerForm
    getScannerFormDetails
}
