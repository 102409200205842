import React,{ useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {Row, Col, Form, Typography, Table, Button, Space} from 'antd'
import { DeleteOutlined, EditOutlined, QrcodeOutlined, SearchOutlined } from '@ant-design/icons'
import { AddUpdateCampaign, ModuleTopHeading, StoreInput, ActionButton, QRsModal, RemoveModal, StoreTimePicker} from '../../components';
import { actionsApi, localToUtcOnlyDate2, simpleDateFormat, TableLoader } from '../../shared'
const {Text}= Typography
let timer
const Campaigns = () => {

    const [form] = Form.useForm()
    const dispatch= useDispatch()
    const {data, loading, filters, pagination}= useSelector(state => state?.campaigns)
    const [visible, setVisible]= useState(false)
    const [visibleQRsModal, setVisibleQRsModal]= useState(false)
    const [visibleRemoveModal, setVisibleRemoveModal]= useState(false)
    const [rowData, setRowData]= useState(null)
    const [campaignID, setCampaignID]= useState(null)
    const [campaignName, setCampaignName]= useState(null)

    useEffect(()=>{
        const {range, name}= filters
        let fromDate=null
        let toDate= null
        if(range?.length)
        {
            fromDate= localToUtcOnlyDate2(new Date(range[0]))
            toDate= localToUtcOnlyDate2(new Date(range[1]))
        }
        dispatch(actionsApi?.viewAllCampaigns({name, fromDate, toDate, ...pagination, pageNo: 1}))
        form.setFieldsValue({
            ...filters,
        })
    }, [])

    const filter=()=>{
        const {range, name}= form.getFieldsValue()
        let fromDate=null
        let toDate= null
        if(range?.length)
        {
            fromDate= localToUtcOnlyDate2(new Date(range[0]))
            toDate= localToUtcOnlyDate2(new Date(range[1]))
        }
        dispatch(actionsApi?.viewAllCampaigns({name, fromDate, toDate, ...pagination, pageNo:1}))
        dispatch(actionsApi?.changeCampaignsFilters({name, range}))
    }
    const call=(pageNo=1, pageSize=5)=>{
        const {range, name}= form.getFieldsValue()
        let fromDate=null
        let toDate= null
        if(range?.length)
        {
            fromDate= localToUtcOnlyDate2(new Date(range[0]))
            toDate= localToUtcOnlyDate2(new Date(range[1]))
        }
        dispatch(actionsApi?.viewAllCampaigns({name, fromDate, toDate, pageNo, pageSize}))
    }
    const columns = [
        {
            title: <Text>Campaign name</Text>,
            dataIndex: 'name',
            key: 'name',
            render: column => <Text strong>{column}</Text>
        },
        {
            title: <Text>Campaign Qr type</Text>,
            dataIndex: 'campaignType',
            key: 'campaignType',
            render: column => <Text strong>{column === 1 ? 'Single' : 'Unique'}</Text>
        },
        {
            title: <Text>Start date</Text>,
            dataIndex: 'startDate',
            key: 'startDate',
            render: column => <Text>{simpleDateFormat(column)}</Text>
        },
        {
            title: <Text>End date</Text>,
            dataIndex: 'endDate',
            key: 'version',
            render: column => <Text>{simpleDateFormat(column)}</Text>
        },
        {
            title: <Text>Limit</Text>,
            dataIndex: 'limit',
            key: 'limit',
            render: column => <Text>{column}</Text>
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (_, row) => {
                return (
                    <Space>
                        <ActionButton
                            title='QRs'
                            icon={<QrcodeOutlined />}
                            onClick={() => {setVisibleQRsModal(true); setCampaignName(row?.name); setCampaignID(row?.id)}}
                        />
                        <ActionButton
                            danger
                            icon={<EditOutlined />}
                            onClick={() => {setVisible(true); setRowData(row)}}
                        /> 
                        <ActionButton
                            danger
                            icon={<DeleteOutlined />}
                            onClick={() => {setVisibleRemoveModal(true); setRowData(row?.id)}}
                        />
                    </Space>
                )
            }
        }
    ]
   
    function debounce(func, delay) {
        return function(...args) {
            clearTimeout(timer)
            timer = setTimeout(() => {
                func.apply(this, args)
            }, delay)
        };
    }
    const debouncedSearchHandler = debounce(()=> call(1, 5), 600)

    
  return (
    <>
        <Row 
            gutter={[12,12]} 
            align={'middle'}
        >
            <Col md={12}>
                <ModuleTopHeading
                    name='Campaigns'
                    onClick={()=>setVisible(true)}
                />
            </Col>
            <Col md={12} className='justify-end'>
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={true}
                    onFinish={filter}
                    className='search-bar-cover'
                >
                    <Space>
                        <StoreInput
                            name="name"
                            size='large'
                            label={null}
                            value={form.getFieldValue('name') || ''}
                            suffix={<SearchOutlined />}
                            placeholder='search a campaign'
                            className='page-top-search-bar'
                            onChange={debouncedSearchHandler}
                        />
                        <StoreTimePicker
                            rangePicker
                            name='range'
                            label='From date - To date'
                            format='MM/DD/YYYY'
                            size='large'
                            onChange={filter}
                        />
                    </Space>
                </Form>
            </Col>
            <Col md={24}>
                <Table 
                    size='large'
                    columns={columns} 
                    dataSource={data} 
                    pagination={{ 
                        hideOnSinglePage: true, 
                        total: pagination?.totalRecords,
                        pageSize: pagination?.pageSize,
                        defaultPageSize: pagination?.pageSize,
                        current: pagination?.pageNo,
                        size: "default",
                        pageSizeOptions:['20', '50', '100'],
                        onChange: (pageNo, pageSize)=> call(pageNo, pageSize),
                        showTotal: total => <Button>Total Apps: {total}</Button>
                    }}
                    loading={
                        {
                            ...TableLoader,
                            spinning: loading
                        }
                    }
                />
            </Col>
        </Row>
        <AddUpdateCampaign
            visible={visible}
            getCampaigns={call}
            editData={rowData}
            onClose={()=> {setVisible(false); setRowData(null)}}
        />
        <QRsModal
            visible={visibleQRsModal}
            campaignID={campaignID}
            campaignName= {campaignName}
            onClose={()=>{
                setVisibleQRsModal(false); 
                setCampaignName(null)
                setCampaignID(null);
                dispatch(actionsApi?.qrsClear())

            }}
        />
        {
            visibleRemoveModal &&
            <RemoveModal
                visible={visibleRemoveModal}
                campaignID={rowData}
                getCampaigns={call}
                onClose={()=>{setVisibleRemoveModal(false); setRowData(null)}}
            />
        }
    </>
  )
}

export {Campaigns}