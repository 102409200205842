import { useEffect, createRef } from "react"
import PinInput from "react-pin-input"
const StoreInputPin= ({visible, onComplete})=>{
    let ele= createRef()
    useEffect(()=>{
        if(visible)
            ele.focus()
    }, [visible])
    return (
        <PinInput 
            length={6} 
            initialValue=""
            secret
            secretDelay={200} 
            onComplete={value => onComplete(value)} 
            type="numeric" 
            inputMode="number"
            inputStyle={{borderColor: '#BFBFBF', borderRadius:'6px'}}
            inputFocusStyle={{borderColor: '#EB3412'}}
            autoSelect={true}
            regexCriteria={/^[0-9]*$/}
            ref={(n) => ele=n}
        />
    )
}
export {StoreInputPin}