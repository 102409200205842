import { Progress, Modal } from "antd"
import "./index.css"
const DownloadProgress= ({sheetGenration})=>{
    return (
        <Modal
            open={sheetGenration?.show}
            width={300}
            centered
            footer={null}
            closeIcon={null}
            >
            <div className='px-3  py-3 bg-white center-center'>
                <Progress  
                    percent={sheetGenration?.percent}
                    status="active"
                    size="large"
                    strokeColor='#EB3412'
                    
                    />
            </div>
        </Modal>
    )
}
export {DownloadProgress}