import { Button, Col, Modal, Pagination, Row } from "antd"
import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux";
import JSZip from "jszip";
import { DownloadProgress, QRCode } from "../OtherComponents"
import { actionsApi, apiCalls } from "../../../shared";
const QRsModal= ({visible, campaignID, campaignName, onClose})=>{

    const dispatch= useDispatch()
    const {qrs, qrsPagination}= useSelector(state => state?.campaigns)
    const [state, setState]= useState([])
    const [downloadableQRs, setDownloadableQrs]= useState({qr:[]})
    const [sheetGenration, setSheetGeneration]= useState({show: false, percent: 0})
    useEffect(()=>{
        if(campaignID)
            {dispatch(actionsApi?.getQrsByCampaignID({campaignID, ...qrsPagination}))}
    }, [campaignID])

    useEffect(()=>{
        if(qrs?.length)
            setState(qrs)
        else
            setState([])
    }, [qrs])
    useEffect(()=>{
        if(!visible){
            setDownloadableQrs([])
            setBlobs([])
        }
    }, [visible])
    const [blobs, setBlobs]= useState([])
     const  downloadCanvasImages= async () =>{

        let  parent = document.getElementById('QrDiv')
        let canvases = parent.getElementsByTagName('canvas')
        
        let tempArray=[]
        for (var i = 0; i < canvases.length; i++) {
            var canvas = canvases[i]
            var link = document.createElement('a')
            link.href = canvas.toDataURL()

            const response = await fetch(link.href);
            const blob = await response.blob()
            tempArray=[...tempArray, blob]
        }
        setBlobs([...blobs, ...tempArray])

        setSheetGeneration({...sheetGenration, percent: parseInt(((blobs?.length + tempArray?.length)/qrsPagination?.totalRecords)*100)})
        setTimeout(()=>{generateCSV(downloadableQRs?.start, downloadableQRs?.end)}, 3000)
    }
    
    const call=(pageNo=1, pageSize=5)=>{
        dispatch(actionsApi?.getQrsByCampaignID({campaignID, pageNo, pageSize}))
    }
    const generateCSV= async (start=1, end=2)=>{
        let pageSize= 250
        const totalIterations= Math.ceil(qrsPagination.totalRecords/pageSize)

        let records=[]
        if(start === 1)
            setSheetGeneration({...sheetGenration, show: true})
        if(qrsPagination.totalRecords<=pageSize && start === 1)
            end= start
        for(let i= start;i <= end;i++)
        {
            const result= await apiCalls?.campaign?.getQrsByCampaignID({campaignID, pageNo: i,pageSize})
            if(!result)
            {
                setBlobs([])
                setDownloadableQrs([])
                setSheetGeneration({...sheetGenration, percent:0, show:false})  
                break
            }
            records=[...records,...result]
            if(i===end)
                setDownloadableQrs({qr:[...records], start: start+2, end: end ===totalIterations ?  end : end +2})
            if(i === totalIterations)
                setSheetGeneration({...sheetGenration, percent:0, show:false})  
           
        }
    }
    useEffect(()=>{
        if(downloadableQRs?.qr?.length)
        {
            downloadCanvasImages()
        }
    }, [downloadableQRs])
    useEffect(()=>{

        if(blobs?.length === qrsPagination?.totalRecords)
            {
                setSheetGeneration({...sheetGenration, show: false, percent: 0})
                generateZip()
            }
    }, [blobs])
    const generateZip = async ()=>{
        const zip = new JSZip()
        for(let i=0;i< blobs?.length; i++){
            
            zip.file('canvas_image_' + i + '.png', blobs[i])
            if (i == blobs.length - 1) {
              const zipData = await zip.generateAsync({
                type: "blob",
                streamFiles: true,
              })
              const link = document.createElement("a");
              link.href = window.URL.createObjectURL(zipData);
              link.download = campaignName+".zip";
              link.click();
              setBlobs([])
            }
        }
    }
    return (
        <Modal 
            title={campaignName} 
            open={visible} 
            onOk={onClose} 
            onCancel={onClose}
            width={1100}
            footer={
                <Button type="primary" onClick={()=>{generateCSV(1,2)}}>Download QR</Button>
            }
            destroyOnClose
        >
            <DownloadProgress sheetGenration={sheetGenration}/>
            <Row gutter={[16,16]}>
                {
                    state?.map((qrCode, index)=>
                        <Col 
                            span={4} 
                            key={'qr-code-'+index}
                        >
                            <QRCode 
                                campaignID={qrCode?.campaignID} 
                                qrID={qrCode?.qrID}
                                index={index}
                            />
                        </Col>
                    )
                }
                {
                    state?.length ?
                    <Col span={24} className="justify-center">
                        <Pagination
                            hideOnSinglePage= {true}
                            total= {qrsPagination?.totalRecords}
                            pageSize= {qrsPagination?.pageSize}
                            defaultPageSize= {qrsPagination?.pageSize}
                            current= {qrsPagination?.pageNo}
                            size= {"default"}
                            pageSizeOptions= {['20', '50', '100']}
                            onChange= {(pageNo, pageSize)=> call(pageNo, pageSize)}
                            showTotal= {total => <Button>Total Apps: {total}</Button>}
                        />
                    </Col>
                    :<></>
                }
                <div style={{display:'none', flexWrap: 'wrap'}} id="QrDiv">
                    {
                        downloadableQRs?.qr?.map((qrCode, index)=>
                            <span 
                                key={'qr-code-'+index}
                            >
                                <QRCode 
                                    campaignID={qrCode?.campaignID} 
                                    qrID={qrCode?.qrID}
                                    index={index}
                                />
                            </span>
                        )
                    }
                </div>
            </Row>
        </Modal>
    )
}
export {QRsModal}