import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Col, Row, Form, Button} from "antd"
import { ModuleTopHeading, StoreInput, StoreSingleUpload } from "../../components"
import { actionsApi, apiCalls } from "../../shared"
import "./index.css"

const ScannerDetails= ()=>{
    const dispatch= useDispatch()
    const {data}= useSelector(state => state?.scannerForm)
    const [form] = Form.useForm()
    const [loading, setLoading]= useState(false)

    useEffect(()=>{
        dispatch(actionsApi?.getScannerFormDetails())
    }, [])
    useEffect(()=>{
        if(data)
            form.setFieldsValue({...data})
        else
            form.resetFields()
    }, [data])
    const addScannerFormDetails= async ()=>{
        setLoading(true)
        let {url, logo}=form.getFieldsValue()
        let formData = new FormData()
        formData.append(`url`, url) 
        formData.append(`logo`, logo) 
        if(data?.id)
            formData.append(`id`, data?.id) 
        await apiCalls?.scannerFormDetails?.addScannerFormDetails(formData)
        setLoading(false)
    }
    return (
        <div className="py-3">
            <Form
                form={form}
                layout="vertical"
                initialValues={true}
                onFinish={addScannerFormDetails}
                className="scanner-form-cover"
            >
                <Row gutter={[16, 16]}>
                    <Col md={24}>
                        <ModuleTopHeading
                            name='Scanner Form Details'
                        />
                    </Col>
                    <Col sm={24} xs={24}>
                        <StoreSingleUpload
                            name='logo'
                            label='Upload logo'
                            required
                            message='please upload logo'
                            form={form}
                            logo={data?.logo || null}
                        />
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                        <StoreInput
                            name="url"
                            label= 'Redirect'
                            value={form.getFieldValue('url') || ''}
                            placeholder='https://www.damodarcinemas.com.fj/village-6-suva/movie/hanu-man'
                            required
                            message='please enter redirect url'
                            size='large'
                        />
                    </Col>
                    <Col span={24}>
                        <Button 
                            type="primary" 
                            htmlType="submit"
                            loading={loading}
                        >
                            Save
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}
export {ScannerDetails}