import { useSelector, useDispatch } from "react-redux"
import { Form, Image, Button, Space, Row, Col, Typography} from "antd"
import { StoreInput } from '../../components'
import { actionsApi } from "../../shared"
import "./index.css"
import { useState } from "react"
import { LeftOutlined } from "@ant-design/icons"
const {Title,Link}= Typography

const Login = () => {

    const appDispatcher = useDispatch()
    const [form] = Form.useForm()
    const { loading } = useSelector(state => state?.login)
    const [forgotPassword, setForgotPassword]= useState(false)
    const login = () => {
        if(forgotPassword)
            appDispatcher(actionsApi?.forgotPassword(form.getFieldsValue(['email'])))
        else
            appDispatcher(actionsApi?.login(form.getFieldsValue(['email', 'password'])))

    }
    return (
        <div 
            className='login-form-cover'
            >
            <div className="center" style={{background:'#B7260B'}}>
                <Row className="login-form border-grey m-0">
                    <Col md={12} sm={24} xs={24}>
                        <Space 
                            direction='vertical' 
                            className='py-5 px-4'
                            size={15}
                            >
                            <div>
                                <div className="justify-center">
                                    <Image
                                        src={"./assets/images/logo.png"}
                                        alt='Store App Admin Panel'
                                        preview= {false}
                                    />
                                </div>
                                <Title 
                                    level={5} 
                                    className="my-0 pt-3 text-center brand-color"
                                    >
                                    {
                                        forgotPassword ? 'Forgot password':
                                        'Login to access your dashboard'
                                    }
                                </Title>
                            </div>
                            <Form
                                form={form}
                                layout="vertical"
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={login}
                                style={{ width: '100%' }}
                            >
                                <Row className="m-0">
                                    <Col span={24}>
                                        <Space direction="vertical" className="width-100">
                                            {
                                                forgotPassword ?
                                                <Button 
                                                    onClick={()=> setForgotPassword(false)}
                                                    icon={<LeftOutlined/>}
                                                    >
                                                </Button>
                                                :<></>
                                            }
                                            <StoreInput
                                                name="email"
                                                label="Email"
                                                required
                                                message='please enter email'
                                                size='large'
                                                value={form.getFieldValue('email') || ''}
                                            />
                                        </Space>
                                    </Col>
                                    {
                                        forgotPassword ? <></> :
                                        <Col span={24}>
                                            <StoreInput
                                                name="password"
                                                type='password'
                                                label="Password"
                                                size='large'
                                                required
                                                message='please enter password'
                                                value={form.getFieldValue('password') || ''}
                                            />
                                        </Col>
                                    }
                                    <Col span={24}>
                                        <Space direction="vertical" className="width-100">
                                            {
                                                !forgotPassword?
                                                <Link 
                                                    href="#" 
                                                    target="_blank"
                                                    onClick={e => {e.preventDefault(); setForgotPassword(true)}}
                                                >
                                                    forgot password?
                                                </Link>
                                                :<></>
                                            }
                                            <Button 
                                                type="primary"
                                                htmlType="submit" 
                                                size="large"
                                                block
                                                loading={loading}
                                            >
                                                {
                                                    forgotPassword ? 'Send email' : 'Login'
                                                }
                                            </Button>
                                        </Space>
                                    </Col>
                                </Row>
                            </Form>
                        </Space>
                    </Col>
                    <Col 
                        md={12} sm={0} xs={0}
                        className="bg-gray2 center"
                    >
                        <Image 
                            src="/assets/images/log999.png" 
                            preview={false} 
                        />
                    </Col>
                </Row>
            </div>
        </div>
    )
}
export {Login}