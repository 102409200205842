import { configureStore } from "@reduxjs/toolkit";
import {
	login,
	dashboard,
	campaigns,
	campaignOverview,
	applications,
	scannerForm
} from "./action"
const store = configureStore({
	reducer: {
		login,
		dashboard,
		campaigns,
		campaignOverview,
		applications,
		scannerForm
	},
})
export default store