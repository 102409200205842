import { QRCode as QrCode, Button } from "antd"
const QRCode= ({campaignID, qrID})=>{
    const downloadQRCode = () => {
        const canvas = document.getElementById('myqrcode'+campaignID+qrID)?.querySelector('canvas');
        console.log('myqrcode'+campaignID+qrID)
        console.log(canvas)
        if (canvas) {
          const url = canvas.toDataURL();
          const a = document.createElement('a')
          a.download = 'QRCode.png'
          a.href = url;
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
    }
    return (
        <div 
            id={'myqrcode'+campaignID+qrID}
            className="flex-col-center"
        >
            <QrCode 
                value={"https://registration.damodarcinemas.com.fj/scanner-details/"+campaignID+"/qrID/"+qrID} 
                bgColor="#fff" 
                style={{ marginBottom: 16 }} 
            />
            <Button onClick={downloadQRCode} style={{display: 'none'}}>
                Download
            </Button>
        </div>
    )
}
export {QRCode}