import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../..';
import { checkAuthorization } from '../../../helpers'
import { message } from 'antd'


export const viewCampaignOverviewByID = createAsyncThunk('getCampaignOverview',
    async (object, { dispatch, getState }) => {

        const {userToken}= checkAuthorization()
        dispatch(gettingCampaignOverview())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append("pageNO", object?.pageNo)
        myHeaders.append("recordsPerPage", object?.pageSize)
        var requestOptions = {
            method: 'Post',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({campaignID: object?.campaignID, name: object?.name})
        };
        fetch(domainUrl + `/viewUserDetails`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    dispatch(campaignOverviewResponse(result?.clients))
                    dispatch(setPages({pageNo: object?.pageNo, pageSize: object?.pageSize, totalRecords: result?.totalRecords}))
                }
                else 
                    throw result?.message
            })
            .catch(error => { 
                dispatch(campaignOverviewClear())
                message.error(error) 
            })
    }
)
export const getCampaignsLookup= createAsyncThunk('getCampaignsLookup',
    async (object, { dispatch, getState }) => {

        const {userToken}= checkAuthorization()
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        myHeaders.append("Content-Type", "application/json")
        var requestOptions = {
            method: 'post',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(object)
        };
        fetch(domainUrl + `/getAllCampaign`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    dispatch(campaignsLookupResponse(result?.campaigns))
                }
                else 
                    throw result?.message
            })
            .catch(error => { 
                dispatch(campaignsLookupClear())
                message.error(error) 
            })
    }
)
export const getCampaignWinner= createAsyncThunk('getCampaignWinner',
    async (object, { dispatch, getState }) => {
        const {userToken}= checkAuthorization()
        dispatch(campaignWinnerLoading())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append("pageNO", 1)
        myHeaders.append("recordsPerPage", 999)
        var requestOptions = {
            method: 'Post',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(object)
        };
        fetch(domainUrl + `/viewLuckyWinners`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    dispatch(campaignWinnerResponse(result?.luckyWinner))
                }
                else 
                    throw result?.message
            })
            .catch(error => { 
                dispatch(campaignWinnerClear())
                message.error(error) 
            })
    }
)

const CampaignOverviewSlice = createSlice({
    name: 'campaignOverview',
    initialState: {
        data: null,
        loading: false,
        range:[],
        pagination:{
            pageNo:1,
            pageSize: 20,
            totalRecords:0,
        },
        filters: {
            campaignID: '',
            name: '',
        },
        campaignsLookup: [],
        campaignWinnerLoading: false,
        campaignWinner: null
    },
    reducers: {
        gettingCampaignOverview: (state) => {
            state.loading = true
        },
        campaignOverviewResponse: (state, action) => {
            state.data = action.payload;
            state.loading = false
        },
        campaignOverviewClear: (state) => {
            state.data = null;
            state.loading = false
        },
        changeCampaignOverviewFilters: (state, action) => {
            state.filters = {...state.filters, ...action.payload}
        },
        setPages:(state, action)=>{
            state.pagination= action.payload
        },
        campaignsLookupResponse: (state, action) => {
            state.campaignsLookup= action.payload
        },
        campaignsLookupClear: (state) => {
            state.campaignsLookup= []
        },
        campaignWinnerLoading: (state) => {
            state.campaignWinnerLoading= true
        },
        campaignWinnerResponse: (state, action) =>{
            state.campaignWinnerLoading= false
            state.campaignWinner= action.payload
        },
        campaignWinnerClear: (state) =>{
            state.campaignWinnerLoading= false
            state.campaignWinner= null
        }
    },
})
export const { 
    gettingCampaignOverview, campaignOverviewResponse, campaignOverviewClear, changeCampaignOverviewFilters, setPages,
    campaignsLookupResponse, campaignsLookupClear,
    campaignWinnerLoading, campaignWinnerResponse, campaignWinnerClear
} = CampaignOverviewSlice.actions;
export default CampaignOverviewSlice.reducer;