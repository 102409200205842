import { domainUrl } from "../../"
import { checkAuthorization } from "../../helpers"
import { message } from "antd"
const addUpdateCampaign = (data) => {
    const {userToken}= checkAuthorization()
    var myHeaders = new Headers()
    myHeaders.append("Authorization", `${userToken}`)
    myHeaders.append("Content-Type", "application/json")
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(data)
    }
    let url = data?.id ? '/updateCampaign' : '/createCampaign'
    return (
        fetch(domainUrl + url, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success(result?.message)
                return 1
            }
            else 
                throw result?.message
        })
        .catch(error => {
            message.error(error)
            return 0
        })
    )
}
const removeCampaign = (object) => {
    const {userToken}= checkAuthorization()
    var myHeaders = new Headers()
    myHeaders.append("Authorization", `${userToken}`)
    myHeaders.append("Content-Type", "application/json")
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(object)
    }
    return (
        fetch(domainUrl + '/deleteCampaign', requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success(result?.message)
                return 1
            }
            else 
                throw result?.message
        })
        .catch(error => {
            message.error(error)
            return 0
        })
    )
}
const getQrsByCampaignID= (object)=>{
    const {userToken}= checkAuthorization()
    var myHeaders = new Headers();
    myHeaders.append("Authorization", userToken)
    myHeaders.append("Content-Type", "application/json")
    myHeaders.append("pageNO", object?.pageNo)
    myHeaders.append("recordsPerPage", object?.pageSize)
    var requestOptions = {
        method: 'get',
        headers: myHeaders,
        redirect: 'follow'
    };
    return (
        fetch(domainUrl + `/getQRsByCampaginID/${object?.campaignID}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                return  result?.QRs
            }
            else 
                throw result?.message
        })
        .catch(error => { 
            message.error(error) 
        })
    )
}
const campaign={
    addUpdateCampaign,
    removeCampaign,
    getQrsByCampaignID
}
export {campaign}