import { useEffect, useState } from "react"
import { useSelector, useDispatch} from "react-redux"
import { Drawer, Button, Space, Table, Typography, Row, Col } from "antd"
import { actionsApi, TableLoader } from "../../../shared"
import { ChooseWinnerModal, SendMailModal } from "../"
import { MailOutlined, TrophyFilled, TrophyOutlined } from "@ant-design/icons"
import { ActionButton } from "../../PageComponents"
const {Text}= Typography

const WinnersDrawer= ({visible, campaignID, campaignDetail, onClose})=>{

    const { campaignWinner, campaignWinnerLoading }= useSelector(state => state?.campaignOverview)
    const [visibleChooseWinnerModal, setVisibleChooseWinnerModal]= useState(false)
    const [visibleMailModal, setVisibleMailModal]= useState(false)
    const [winnerID, setWinnerID]= useState(null)
    const [row, setRow]= useState(null)
    const dispatch= useDispatch()
    useEffect(()=>{
        if(campaignID && visible){
            dispatch(actionsApi?.getCampaignWinner({campaignID}))
        }
        else
            dispatch(actionsApi?.campaignWinnerClear())
    }, [campaignID, visible])

    const columns = [
        {
            title: <Text>Scanner Name</Text>,
            dataIndex: 'name',
            key: 'name',
            render: column => <Text strong>{column}</Text>
        },
        {
            title: <Text>Email</Text>,
            dataIndex: 'email',
            key: 'email',
            render: column => <Text>{column}</Text>
        },
        {
            title: <Text>phone #</Text>,
            dataIndex: 'phone',
            key: 'phone',
            render: column => <Text>{column}</Text>
        },
        {
            title: <Text>Transaction ID</Text>,
            dataIndex: 'transactionNumber',
            key: 'transactionNumber',
            render: column => <Text>{column}</Text>
        },
        {
            title: 'action',
            dataIndex: 'mail',
            key: 'mail',
            render: (column, row) => {
                return (
                    <Space>
                        <ActionButton
                            title='re-draw winner'
                            icon={<TrophyOutlined />}
                            type='primary'
                            onClick={() => {setVisibleChooseWinnerModal(true); setWinnerID(row?.winnerID)}}
                        /> 
                        <ActionButton
                            title={column? 'mail sended' : 'send mail?'}
                            icon={<MailOutlined />}
                            danger
                            type={column? 'primary' : 'default'}
                            onClick={() => {setVisibleMailModal(true); setWinnerID(row?.winnerID); setRow(row)}}
                        />
                    </Space>
                )
            }
        }
    ]
    return (
        <Drawer
            title='Winners'
            onClose={onClose}
            open={visible}
            width={800}
            footer={null}
        >
            <Row gutter={[12,12]}>
                <Col span={24}>
                    <Button  
                        icon={<TrophyFilled/>}
                        onClick={()=> setVisibleChooseWinnerModal(true)}>Choose winner</Button>
                </Col>
                <Col span={24}>
                    <Table 
                        columns={columns} 
                        dataSource={campaignWinner} 
                        loading={
                            {
                                ...TableLoader,
                                spinning: campaignWinnerLoading
                            }
                        }
                        pagination={{
                            hideOnSinglePage: true
                        }}
                    />
                </Col>
            </Row>
            {
                visibleChooseWinnerModal &&
                <ChooseWinnerModal
                    visible={visibleChooseWinnerModal}
                    campaignID={campaignID}
                    winnerID={winnerID}
                    campaignDetail={campaignDetail}
                    callBack={()=> dispatch(actionsApi?.getCampaignWinner({campaignID}))}
                    onClose={()=> {setVisibleChooseWinnerModal(false); setWinnerID(null)}}
                />
            }
            {
                visibleMailModal &&
                <SendMailModal
                    visible={visibleMailModal}
                    campaignID={campaignID}
                    winnerID={winnerID}
                    winner={row}
                    callBack={()=> dispatch(actionsApi?.getCampaignWinner({campaignID}))}
                    onClose={()=> {setVisibleMailModal(false); setWinnerID(null); setRow(null)}}
                />
            }
        </Drawer>
    )
}
export {WinnersDrawer}