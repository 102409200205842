import { domainUrl } from "../../"
import { checkAuthorization } from "../../helpers"
import { message } from "antd"
const checkPin = (data) => {
    const {userToken}= checkAuthorization()
    var myHeaders = new Headers()
    myHeaders.append("Authorization", `${userToken}`)
    myHeaders.append("Content-Type", "application/json")
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(data)
    }
    return (
        fetch(domainUrl + '/checkPin', requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) 
                return 1
            else 
                throw result?.message
        })
        .catch(error => {
            message.error(error)
            return 0
        })
    )
}
const chooseWinner = (data) => {
    const {userToken}= checkAuthorization()
    var myHeaders = new Headers()
    myHeaders.append("Authorization", `${userToken}`)
    myHeaders.append("Content-Type", "application/json")
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(data)
    }
    let url= data?.winnerID ? "/redraw" : "/chooseWinner"
    return (
        fetch(domainUrl + url, requestOptions)
        .then(response => response.json())
        .then(result => {
            let success= parseInt(result?.success)
            if (success > 0) {
                return result?.winner
            }
            else if (success === -1) {
                message.error(result?.message)
                return -1
            }
            else 
                throw result?.message
        })
        .catch(error => {
            message.error(error)
            return 0
        })
    )
}
const sendMail = (data) => {
    const {userToken}= checkAuthorization()
    var myHeaders = new Headers()
    myHeaders.append("Authorization", `${userToken}`)
    myHeaders.append("Content-Type", "application/json")
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(data)
    }
    return (
        fetch(domainUrl + '/sendEmail', requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success(result?.message)
                return 1
            }
            else 
                throw result?.message
        })
        .catch(error => {
            message.error(error)
            return 0
        })
    )
}
const removeScanner = (scannerID) => {
    const {userToken}= checkAuthorization()
    var myHeaders = new Headers()
    myHeaders.append("Authorization", `${userToken}`)
    var requestOptions = {
      method: 'Delete',
      headers: myHeaders,
      redirect: 'follow'
    }
    return (
        fetch(domainUrl + `/deleteUserDetails/${scannerID}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success(result?.message)
                return 1
            }
            else 
                throw result?.message
        })
        .catch(error => {
            message.error(error)
            return 0
        })
    )
}
const campaignOverview={
    chooseWinner,
    checkPin,
    sendMail,
    removeScanner
}
export {campaignOverview, checkPin, sendMail}